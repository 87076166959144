import React, { useState, useEffect, CSSProperties } from 'react'
import axios from 'axios'
import { getUser } from '../../../utils/Session'
import { useHistory, useLocation } from 'react-router'
import { COLORS, FONTS, FONT_WEIGHTS } from '../../../constants/theme'

const CartIcon = () => {
  const [cartLength, setCartLength] = useState<number>(0)
  const history = useHistory()
  const location = useLocation()

  const styles: { [key: string]: CSSProperties } = {
    wrapper: {
      position: 'relative',
      top: cartLength > 0 ? '10px' : 0
    },
    cartIcon: {
      // color is set on svg
      cursor: 'pointer',
      width: '40px',
      height: '40px',
      marginRight: '15px'
    },
    badge: {
      width: '20px',
      height: '20px',
      backgroundColor: COLORS.orange,
      borderRadius: 9000,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      bottom: '50px',
      left: '30px'
    },
    number: {
      fontSize: '17px',
      fontFamily: FONTS.sansSerif,
      fontWeight: FONT_WEIGHTS.heavy,
      color: COLORS.offWhite
    }
  }

  useEffect(() => {
    getCartLength()
  }, [location])

  const getCartLength = async () => {
    const userId = await getUser()?.id

    const cartId =  await axios.get(`/api/cart/id/${userId}`).then(res => {
      if (res.data?.mostRecentCart) return res?.data?.mostRecentCart[0]?.id
      else return undefined
    }).catch(err => console.error(err))

    
    if (cartId) {
      const defaultLength = { data: [] }

      const [
        printsLength = defaultLength,
        clothingLength = defaultLength,
        merchLength = defaultLength
      ] = await Promise.all([
        axios.get(`/api/cart-prints/id/${cartId}`),
        axios.get(`/api/cart-clothing/id/${cartId}`),
        axios.get(`/api/cart-merch/id/${cartId}`)
      ])

      setCartLength(printsLength.data.length + clothingLength.data.length + merchLength.data.length)
    }
  }

  return (
    <button style={styles.wrapper} onClick={() => history.push('/cart')}>
      <img
        style={styles.cartIcon}
        src='../../assets/images/cart_icon.svg'
        alt='Shopping Cart Icon'
      />

      {
        cartLength > 0 &&
        <div style={styles.badge}><p style={styles.number}>{cartLength}</p></div>
      }
    </button>
  )
}

export default CartIcon
