import React, { CSSProperties } from 'react'
import { COLORS, FONTS, FONT_WEIGHTS } from '../../../constants/theme'
import useViewportType from '../../../utils/useViewportType'

const styles: { [key: string]: CSSProperties } | any = {
  wrapper: (isMobile: boolean) => ({
    marginTop: isMobile ? '0' :'-100px'
  }),
  wave: {
    width: '100vw',
    position: 'relative',
    bottom: '-26.3vw'
  },
  backgroundWrapper: (isMobile: boolean) => ({
    backgroundImage: 'url("../../assets/images/footer_background.jpg")',
    content: '',
    backgroundSize: 'cover',
    width: '100vw',
    minHeight: isMobile ? '50vh' : '60vw',
    paddingTop: isMobile ? '50px' : '100px',
    // paddingBottom: isMobile ? '100px' : '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  eqLogo: (isMobile: boolean) => ({
    width: `${isMobile ? '250px' : '500px'}`,
    height: `${isMobile ? '165.5px' : '333.3px'}`,
    marginTop: '120px'
  }),
  socialLinksWrapper: (isMobile: boolean) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '40px',
    width: `${isMobile ? '150px' : '200px'}`
  }),
  socialLinkImage: (isMobile: boolean) => ({
    width: `${isMobile ? '35px' : '50px'}`,
    height: `${isMobile ? '35px' : '50px'}`
  }),
  emailCTA: {
    marginTop: '40px'
  },
  emailHeader: {
    fontFamily: FONTS.serif,
    color: COLORS.yellow,
    fontWeight: FONT_WEIGHTS.light,
    fontSize: '28px'
  },
  emailLink: {
    display: 'flex',
    flexWrap: 'none',
    marginTop: '8px'
  },
  emailLogo: {
    width: '20px',
    height: '20px',
    marginRight: '10px'
  },
  email: {
    fontFamily: FONTS.sansSerif,
    color: COLORS.offWhite,
    textDecoration: 'underline',
    textDecorationColor: COLORS.offWhite,
    fontSize: '16px'
  },
  copyright: {
    fontFamily: FONTS.sansSerif,
    color: COLORS.yellow,
    fontSize: '16px',
    marginTop: '40px',
    marginBottom: '60px'
  }
}

const Footer = () => {
  const isMobile = useViewportType(700)

  return (
    <section style={styles.wrapper(isMobile)}>
      <img
        style={styles.wave}
        src='../../assets/images/wave_footer.svg'
        alt='Overlapping Footer SVG'
      />
      <div style={styles.backgroundWrapper(isMobile)}>
        <img
          style={styles.eqLogo(isMobile)}
          src='../../assets/images/eq_logo_footer.svg'
          alt='Enchanted Quill Footer Logo'
        />
        <div style={styles.socialLinksWrapper(isMobile)}>
          <a
            href='https://www.instagram.com/enchanted_quill/'
            target='_blank'
            rel='noreferrer'
          >
            <img
              style={styles.socialLinkImage(isMobile)}
              src='../../assets/images/instagram_logo.svg'
              alt='Instagram Link'
            />
          </a>
          <a
            href='https://www.facebook.com/EnchantedQuillArt?mibextid=LQQJ4d'
            target='_blank'
            rel='noreferrer'
          >
            <img
              style={styles.socialLinkImage(isMobile)}
              src='../../assets/images/facebook_logo.svg'
              alt='Facebook Link'
            />
          </a>
          <a
            href='https://www.tiktok.com/@enchantedquill'
            target='_blank'
            rel='noreferrer'
          >
            <img
              style={styles.socialLinkImage(isMobile)}
              src='../../assets/images/tiktok_logo.svg'
              alt='TikTok Link'
            />
          </a>
        </div>
        <div style={styles.emailCTA}>
          <h2 style={styles.emailHeader}>Say Hello</h2>
          <a
            href='mailto:enchantedquillartwork@gmail.com'
            target='_blank'
            rel='noreferrer'
            style={styles.emailLink}
          >
            <img
              style={styles.emailLogo}
              src='../../assets/images/gmail_icon.svg'
              alt='Gmail Link'
            />
            <p style={styles.email}>enchantedquillartwork@gmail.com</p>
          </a>
        </div>
        <p style={styles.copyright}>&#169;2023 ENCHANTED QUILL ARTWORK, LLC</p>
      </div>
    </section>
  )
}

export default Footer
