import React, { CSSProperties } from 'react'
import { COLORS, FONTS, FONT_WEIGHTS } from '../../constants/theme'
import useViewportType from '../../utils/useViewportType'
import { useHistory } from 'react-router-dom'

interface ImageLinksImages {
  printLocation: string | undefined
  clothingLocation: string | undefined
  merchLocation: string | undefined
}

interface Props {
  imageLinksImages: ImageLinksImages
}

const styles: { [key: string]: CSSProperties } | any = {
  wrapper: (isMobile: boolean) => ({
    width: isMobile ? '87.5vw' : '80vw',
    margin: 'auto',
    display: 'grid',
    gridTemplateColumns: `repeat(${isMobile ? 1 : 3}, 1fr)`,
    gridGap: `${isMobile ? '84px' : '20px'}`,
    justifyContent: 'center'
  }),
  item: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingTop: '100%',
    cursor: 'pointer'
  },
  title: {
    fontFamily: FONTS.serif,
    fontWeight: FONT_WEIGHTS.medium,
    color: COLORS.darkBlue,
    fontSize: '28px',
    position: 'absolute',
    bottom: '-40px',
    left: '50%',
    transform: 'translate(-50%, 0)',
    whiteSpace: 'nowrap'
  },
  image: (imageLocation: string) => ({
    backgroundImage: `url(${imageLocation})`,
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  })
}

const ImageLinks = ({ imageLinksImages }: Props) => {
  const isMobile = useViewportType(700)
  const history = useHistory()

  return (
    <section style={styles.wrapper(isMobile)}>
      <div style={styles.item}>
        <h2 style={styles.title}>Art Prints</h2>
        <div
          onClick={() => history.push('/print-gallery')}
          style={styles.image(imageLinksImages.printLocation)}
        ></div>
      </div>
      <div style={styles.item}>
        <h2 style={styles.title}>Clothing</h2>
        <div
          onClick={() => history.push('/clothing-gallery')}
          style={styles.image(imageLinksImages.clothingLocation)}
        ></div>
      </div>
      <div style={styles.item}>
        <h2 style={styles.title}>Merch</h2>
        <div
          onClick={() => history.push('/merch-gallery')}
          style={styles.image(imageLinksImages.merchLocation)}
        ></div>
      </div>
    </section>
  )
}

export default ImageLinks
