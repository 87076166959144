import React from 'react'
import { useEffect } from 'react'
import CommissionsParagraph from '../components/CommissionsComponents/CommissionsParagraph'
import CommissionsForm from '../components/CommissionsComponents/CommissionsForm'

function About() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <CommissionsParagraph />
      <CommissionsForm />
    </>
  )
}

export default About
