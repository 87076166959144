import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

interface Clothing {
  id: string;
  printName: string;
  descName: string;
  price: number;
  description: string;
  primaryImage: string;
  secondaryImage: string;
  supplementaryImage: string | null | undefined;
}

function SingleClothingEdit(props: Clothing) {
  const printName = useFormInput(props.printName);
  const descName = useFormInput(props.descName);
  const price = useFormInput(props.price);
  const description = useFormInput(props.description);

  const handleSubmit = (e: Event) => {
    e.preventDefault();

    axios
      .put(`/api/clothing/update/id/${props.id}`, {
        printName: printName.value,
        descName: descName.value,
        price: price.value,
        description: description.value,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          iconColor: "#41F2A0",
          title: "<span>Clothing updated successfully!</span>",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "<span>NO. GOD PLEASE NO. AN ERROR OCCURED!",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  return (
    <section className="singleItemEditWrapper">
      <form encType="multipart/form-data" className="createPrintForm">
        <h1>
          Edit {props.printName}, {props.descName}
        </h1>

        <h3 className="createPrintName">Print Name</h3>
        <textarea
          {...printName}
          className="editItemName editTextarea"
          placeholder="show me print name"
        ></textarea>

        <h3 className="createPrintName">Descriptive Name</h3>
        <textarea
          {...descName}
          className="editItemName editTextarea"
          placeholder="you got this in my size?"
        ></textarea>

        <h3 className="createPrintSmallPrice">Price</h3>
        <textarea
          {...price}
          className="editItemPrice editTextarea"
          placeholder="1234"
        ></textarea>

        <h3 className="createMerchDesc">Description</h3>
        <textarea
          {...description}
          className="createMerchDescInput"
          placeholder="let me tell you something. im tired. ive really put my heart and soul into this code. and so help me god, if you dont reaaaaaally put everything youve got into this description, you will be putting shame on my name. so give it all you got. go get em, tiger"
        ></textarea>

        <button className="submitBtn" onClick={(e: any) => handleSubmit(e)}>
          Save Clothing Item
        </button>
      </form>
    </section>
  );
}

const useFormInput = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  return {
    value,
    onChange: handleChange,
  };
};

export default SingleClothingEdit;
