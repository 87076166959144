import React from "react";
import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import axios from "axios";
import { getUser } from "../../utils/Session";
import "./style.css";
import { createPrintfulOrder } from "../../utils/createPrintfulOrder";

function SuccessfulOrderComponent() {
  const [cartId, setCartId] = useState<number>();

  const history = useHistory();

  const userId = getUser().id;

  // set cartId or redirect to homepage
  useEffect(() => {
    axios.get(`/api/cart/id/${userId}`).then((res) => {
      if (res.data.mostRecentCart.length) {
        setCartId(res.data.mostRecentCart[0].id);
      } else {
        history.push("/");
      }
    });

    createPrintfulOrder();
  }, []);

  useEffect(() => {
    handleDataAfterSuccess();
  }, [cartId]);

  const handleDataAfterSuccess = async () => {
    if (cartId) {
      // get cartClothing and delete associated Clothing by clothingId
      await axios
        .get(`/api/cart-clothing/id/${cartId}`)
        .then((res) => {
          if (res.data.length) {
            for (let i = 0; i < res.data.length; i++) {
              axios.delete(`/api/clothing/delete/id/${res.data[i].clothingId}`);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });

      // get cartMerchItems and delete associated MerchItems by merchId
      await axios
        .get(`/api/cart-merch/id/${cartId}`)
        .then((res) => {
          if (res.data.length) {
            for (let i = 0; i < res.data.length; i++) {
              axios
                .put(`/api/merch/update/id/${res.data[i].merchId}`, {
                  quantity: -res.data[i].quantity,
                })
                .catch((err) => console.error(err));
            }
          }
        })
        .catch((err) => console.error(err));

      // delete user's cart
      await axios
        .delete(`/api/cart/delete/id/${userId}`)
        .catch((err) => console.error(err));
    }
  };

  return (
    <section className="successful-order-section">
      <h1 className="success-line-1">It's ordered!</h1>
      <h2 className="success-line-2">
        You will receive a shipping update within the next 24 hours regarding
        your order.
      </h2>
      <h3 className="success-line-3">
        Thank you so much for your support of my artwork. I hope you love it as
        much as I loved making it.
      </h3>

      <button className="success-done-btn" onClick={() => history.push("/")}>
        Done
      </button>
    </section>
  );
}

export default SuccessfulOrderComponent;
