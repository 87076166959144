import React from "react";
import "./style.css";

function CommissionsParagraph() {
  const redirectToCommissionsForm = () => {
    window
      .open(
        "https://docs.google.com/forms/d/e/1FAIpQLScXOFUDuUZsqIrIOxQj5DRdokKFdGTal1wZFZ70sKQ0OgxQoA/viewform?usp=sf_link",
        "_blank"
      )
      ?.focus();
  };

  return (
    <header className="commissions-header">
      <h1 className="commissions-header-text">Commissions</h1>
      <p className="commissions-paragraph">
        <strong className="commissions-strong-paragraph">
          Are you looking for something customly drawn, just for you?
        </strong>
      </p>
      <p className="commissions-paragraph">
        Commissioning artwork is a way to get an art piece curated for you. It
        is a great gift for others or yourself, and it gives you a way to
        connect with me and my art on a deeper level. If you think a
        commissioned piece is for you, please review the price guide and fill
        out the form linked below!
      </p>
      <div className="commissions-price-guide-wrapper">
        <img
          src="../../assets/images/commissions-price-guide.jpg"
          alt="Commissions Price Guide"
          className="commissions-price-guide-image"
          onClick={() => redirectToCommissionsForm()}
        ></img>
      </div>
      <p className="commissions-paragraph">
        After you fill out this art commission request, I will contact you to go
        over details, timeline and payment before beginning work on the project.
        Please include as many details as possible in this request; reference
        material is encouraged! Also, be sure to review the price guide below
        before getting started.
      </p>
      <p className="commissions-paragraph">
        I look forward to working with you on art drawn uniquely for you, in my
        own style.
      </p>
      <div
        className="redirect-to-commissions-form-btn"
        onClick={() => redirectToCommissionsForm()}
      >
        <p>Request your commission!</p>
      </div>
    </header>
  );
}

export default CommissionsParagraph;
