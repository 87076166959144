import React from 'react';

function EditHero() {
    return (
        <>
        
        </>
    )
}

export default EditHero;