import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "./style.css";

function EditVacationModeComponent() {
  const [vacationModeState, setVacationModeState] = useState<{
    vacationModeOn: 0 | 1;
    message?: string;
  }>();

  // temporarily stores message as it's being written in textarea
  const vacationModeMessage = useFormInput("");

  // retrieve existing Vacation Mode settings from db once
  useEffect(() => {
    axios
      .get("/api/vacation-mode")
      .then((res) => {
        if (res.data?.vacationModeOn) {
          setVacationModeState({
            vacationModeOn: 1,
            message: res.data.message,
          });
        } else {
          setVacationModeState({
            vacationModeOn: 0,
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, []);

  const handleSubmit = (e: Event) => {
    e.preventDefault();

    axios
      .put("/api/vacation-mode/edit", {
        vacationModeOn: vacationModeState?.vacationModeOn,
        message: vacationModeMessage.value,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          iconColor: "#41F2A0",
          title: "<span>Vacation mode successfully updated!</span>",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: "error",
          title:
            "<span>Oops! Something didn't work. Please try again or contact Ian for help.</span>",
          showConfirmButton: false,
          timer: 2500,
        });
      });
  };

  return (
    <section className="edit-vacation-mode-section">
      <form encType="multipart/form-data" className="edit-vacation-mode-form">
        <h1>Set Vacation Mode</h1>

        <h3>Turn Vacation Mode On/Off</h3>
        <h6 className="admin-hint">
          (This doesn't take effect until you save)
        </h6>

        {/* Begin checkbox */}
        <div className="edit-vacation-mode-checkbox-wrapper">
          <div>
            <input
              type="checkbox"
              value="on"
              checked={vacationModeState?.vacationModeOn === 1}
              onClick={() => {
                // if user hasn't started writing/edited vacation mode message,
                // ensure it stays set as message retrieved from db (if one exists)
                if (vacationModeMessage.value === "") {
                  vacationModeMessage.setValue(vacationModeState?.message);
                }
                setVacationModeState((prevState) => ({
                  ...prevState,
                  vacationModeOn: 1,
                }));
              }}
            />
            <label className="vacation-mode-label">ON</label>
          </div>
          <div>
            <input
              type="checkbox"
              value="off"
              checked={!vacationModeState?.vacationModeOn}
              onClick={() => {
                // if user hasn't started writing/edited vacation mode message,
                // ensure it stays set as message retrieved from db (if one exists)
                if (vacationModeMessage.value === "") {
                  vacationModeMessage.setValue(vacationModeState?.message);
                }
                setVacationModeState((prevState) => ({
                  ...prevState,
                  vacationModeOn: 0,
                }));
              }}
            />
            <label className="vacation-mode-label">OFF</label>
          </div>
        </div>
        {/* End Checkbox */}

        <h3>Vacation Page Message</h3>
        <textarea
          // inheriting vacationModeMessage object gives textarea access to value, setValue, and onChange
          {...vacationModeMessage}
          className="edit-vacation-mode-message-textarea"
          defaultValue={vacationModeState?.message}
          placeholder="Let your customers know why the shop's closed and when it will be open again."
        ></textarea>

        <button className="submit-btn" onClick={(e: any) => handleSubmit(e)}>
          Save Vacation Mode Settings
        </button>
      </form>
    </section>
  );
}

// custom method for tracking the values of textareas
const useFormInput = (initialValue: string | undefined) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: any) => {
    e.preventDefault();

    setValue(e.target.value);
  };

  return {
    setValue,
    value,
    onChange: handleChange,
  };
};

export default EditVacationModeComponent;
