import React from 'react'
import { useEffect } from 'react'
import StickersPinsGalleryComponent from '../components/StickersPinsGalleryComponent'

function StickersPinsGallery() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <StickersPinsGalleryComponent />
}

export default StickersPinsGallery
