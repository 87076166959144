import React from "react";
import { useEffect } from "react";
import "./style.css";

function AboutSectionComponent() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <section className="about-section-wrapper">
      <h1 className="about-header">About Enchanted&nbsp;Quill</h1>
      <p className="about-eq-paragraph">
        Enchanted Quill Artwork is an art studio that offers a collection of
        unique and whimsical art pieces, designed and created by Rebecca Stone.
        Her hand-drawn artwork features fantastical creatures and people that
        take you on a journey into a dream-like world.
      </p>
      <p className="about-eq-paragraph">
        Enchanted Quill Artwork offers a variety of products such as art prints,
        hand-printed clothing, and more, all featuring Rebecca's captivating
        designs. If you're looking for something more personal, you can
        commission a custom piece of your own. Enchanted Quill Artwork is
        perfect for those who are seeking to add a touch of enchantment to their
        lives.
      </p>
      <div className="artist-statement-wrapper">
        <img
          src="../../assets/images/eq_rebecca-stone.jpg"
          alt="Rebecca Stone"
          className="beks-about-image"
        />
        <div>
          <p className="artist-statement-paragraph">
            Follow Enchanted Quill's{" "}
            <a href="https://www.tiktok.com/@enchantedquill" target="_blank">
              TikTok
            </a>{" "}
            or{" "}
            <a
              href="https://www.instagram.com/enchanted_quill/"
              target="_blank"
            >
              Instagram
            </a>{" "}
            feeds to keep up on shop updates or learn more about the unique art
            process that goes into making each of these products. If you'd like
            something drawn just for you, fill out a commission form. Have
            questions or concerns? Please email{" "}
            <a href="mailto:enchantedquillartwork@gmail.com">
              enchantedquillartwork@gmail.com
            </a>
            .
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutSectionComponent;
