import React, { useEffect } from "react";
import InstagramFeed from "./InstagramFeed/index";
import "react-ig-feed/dist/index.css";
import "./style.css";

function InstagramComponent() {
  const [error, setError] = React.useState<boolean>(false);
  const directToInstagram = (url: string) => {
    window.open(url, "_blank")?.focus();
  };

  /**
   * How to generate token: https://docs.oceanwp.org/article/487-how-to-get-instagram-access-token
   *
   * 1. Log in to Facebook Developer account https://developers.facebook.com/ using my own personal account
   * 2. Within Facebook dev account, navigate to EQ dashboard
   * 3. Go to Basic Display (under Instagram Basic Display on left sidebar)
   * 4. Scroll down to User Token Generator and click Generate Token
   * 5. Sign in with Enchanted Quill Instagram and grab dat token
   */
  const accessToken =
    "IGQWRNRkxGYU9oSFozbG5abjgyMGlCYVB0MHlOMVFwNktNeEpQczNRMFRRMmI4UHVVS0h1QnNrYmEwc1RCLTFyVFc3cXAxSXRCbTNEb1ozZAVdMaHpTM19teG5fNDN1aVRjMk5neHNIcXBWZAG90eHJQeW1BaVR1OGMZD";

  return !error ? (
    <section className="instagram-section">
      <div
        className="ig-eq-heading"
        onClick={() =>
          directToInstagram("https://www.instagram.com/enchanted_quill/")
        }
      >
        <img
          src="../../assets/images/ig-logo.png"
          alt="Instagram Logo"
          className="ig-eq-heading-logo"
        ></img>
        {/* <h1 className="ig-eq-heading-text">Enchanted Quill</h1> */}
        <img
          src="../../assets/images/whimsical-artwork-logo.png"
          alt="Enchanted Quill Whimsical Artwork"
          className="ig-eq-whimsical-artwork"
        ></img>
      </div>
      <main className="instagram-feed-wrapper">
        <InstagramFeed
          token={accessToken}
          setError={setError}
          counter={25}
          className="instagram-feed"
        />

        <div className="direct-to-ig-btn">
          <a
            href="https://www.instagram.com/enchanted_quill/"
            target="_blank"
            className="direct-to-ig-link"
          >
            <p>See more on Instagram</p>
          </a>
        </div>
      </main>
    </section>
  ) : (
    <div style={{ margin: "100px" }}></div>
  );
}

export default InstagramComponent;
