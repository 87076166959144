import React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import "./style.css";
import Swal from "sweetalert2";

function HotClothing() {
  const [galleryImages, setGalleryImages] = useState<any>([]);

  useEffect(() => {
    axios.get("/api/clothing").then((res) => {
      setGalleryImages(res.data);
    });
  }, []);

  const history = useHistory();

  const selectHotClothing = ({
    printName,
    descName,
    price,
    primaryImage,
    id,
  }: Record<string, string | number>) => {
    Swal.fire({
      title: `<span>Yo, ${printName} do be pretty hot. Is it hot enough tho?</span>`,
      confirmButtonText: "Yes",
      confirmButtonColor: "#002c50",
      showCancelButton: true,
      cancelButtonColor: "#f15a22",
    })
      .then((res) => {
        if (res.isConfirmed) {
          axios
            .post(`/api/hot-clothing/create`, {
              printName: printName,
              descName: descName,
              price: price,
              primaryImage: primaryImage,
              clothing_id: id,
            })
            .then(() => {
              Swal.fire({
                icon: "success",
                iconColor: "#41F2A0",
                title: "<span>Hot print successfully created.</span>",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .then(() => {
              history.go(0);
            });
        } else if (res.isDenied) {
          return;
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <section className="deleteWrapper">
      <h1 className="deleteHeading">Select Hot Clothing</h1>
      <div className="galleryContentWrapper">
        {/* eventually will map from gallery table in database */}
        {galleryImages.map((el: any) => {
          return (
            <div key={el.id} className="galleryItemWrapper">
              <div
                className="galleryImage"
                style={{ backgroundImage: "url(" + el.primaryImage + ")" }}
                onClick={() => selectHotClothing(el)}
              ></div>
              <h3
                className="galleryArtName galleryClothingPrintName"
                onClick={() => selectHotClothing(el)}
              >
                {el.printName},{" "}
                <span className="galleryClothingDescName">{el.descName}</span>
              </h3>
              <h3 className="galleryArtPrice">${el.price}</h3>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default HotClothing;
