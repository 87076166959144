import React, { useState, CSSProperties } from 'react'
import PropTypes from 'prop-types'
import { COLORS, FONTS, FONT_WEIGHTS } from '../../constants/theme'
import { Print, Clothing, Merch } from '../../constants/products'
import { loadCart } from '../../utils/singleProductUtils'
import SingleProductInfo from './SingleProductInfo'
import useViewportType from '../../utils/useViewportType'

interface Props {
  productType: string
  product: Print | Clothing | Merch | {} | any //! FUUUUUCK
  userId?: number
}

const styles: { [key: string]: CSSProperties | any } = {
  imagesWrapper: (isMobile: boolean) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: isMobile ? '100vw' : '100vh'
  }),
  mainImage: (mainImage: string, isMobile: boolean) => ({
    backgroundImage: `url(${mainImage})`,
    width: isMobile ? '80vw' : '73vh',
    height: isMobile ? '80vw' : '73vh',
    margin: '0 auto',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: COLORS.darkBlue
  }),
  selectableImages: (isMobile: boolean) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(30%, 1fr))',
    gap: '0 8px',
    width: isMobile ? '80vw' : '73vh',
    margin: '8px auto'
  }),
  selectableImage: (image: string) => ({
    aspectRatio: '1 / 1',
    backgroundColor: COLORS.darkBlue,
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    cursor: 'pointer'
  }),
  infoWrapper: {
    color: COLORS.darkBlue,
    width: '80vw',
    margin: '32px auto',
    textAlign: 'center'
  },
  name: {
    fontFamily: FONTS.serif,
    fontSize: '32px',
    fontWeight: FONT_WEIGHTS.medium,
    marginBottom: '8px'
  },
  descName: {
    fontFamily: FONTS.sansSerif,
    fontSize: '18px',
    fontWeight: FONT_WEIGHTS.mediumLight
  }
}

const SingleProduct = ({ productType, product, userId }: Props) => {
  const isMobile = useViewportType(900)
  const [cartId, setCartId] = useState<number>()

  const [mainImage, setMainImage] = useState<string>(
    product?.location ?? product?.primaryImage ?? ''
  )

  const setCart = async () => {
    const cart: any = await loadCart(userId) // TODO: cart interface
    if (cart?.length) {
      setCartId(cart[0].id)
    }
  }

  React.useEffect(() => {
    setCart()
  }, [])

  return (
    <section>
      {/* images */}
      <div style={styles.imagesWrapper(isMobile)}>
        {/* hero image */}
        <div style={styles.mainImage(mainImage, isMobile)}></div>

        {/* selectable images */}
        <div style={styles.selectableImages(isMobile)}>
          <div
            style={styles.selectableImage(
              product?.location ?? product?.primaryImage ?? ''
            )}
            onClick={() =>
              setMainImage(product?.location ?? product?.primaryImage ?? '')
            }
          ></div>
          {(product?.candidLocation ?? product?.secondaryImage) && (
            <div
              style={styles.selectableImage(
                product?.candidLocation ?? product?.secondaryImage
              )}
              onClick={() =>
                setMainImage(product?.candidLocation ?? product?.secondaryImage)
              }
            ></div>
          )}
          {product?.supplementaryImage && (
            <div
              style={styles.selectableImage(product.supplementaryImage)}
              onClick={() => setMainImage(product?.supplementaryImage)}
            ></div>
          )}
        </div>
      </div>

      {/* product information */}
      <div style={styles.infoWrapper}>
        <h2 style={styles.name}>{product?.name ?? product?.printName}</h2>
        {/* secondary title for clothing and merch */}
        {product?.descName && (
          <h3 style={styles.descName}>{product.descName}</h3>
        )}

        {/* render price/quantity/size section based on product */}
        <SingleProductInfo
          product={product}
          productType={productType}
          cartId={cartId}
          setCartId={setCartId}
          userId={userId}
        />
      </div>
    </section>
  )
}

SingleProduct.propTypes = {
  productType: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired
}

export default SingleProduct
