import { useEffect, useState } from "react";
import axios from "axios";
import "./style.css";

function VacationModeComponent() {
  /**
   * Vacation Mode logic
   * Since this component is rendered only when vacation mode is on,
   * we're only concerned with extracting the message here
   */
  const [vacationModeMessage, setVacationModeMessage] = useState<string>("");

  useEffect(() => {
    axios
      .get("/api/vacation-mode")
      .then((res) => {
        setVacationModeMessage(res.data.message);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [vacationModeMessage]);

  /**
   * Determine vacation mode banner dynamically based on viewport width
   * Default to showing mobile banner version
   */
  const [windowWidth, setwindowWidth] = useState<number>(899);

  // get window width to determine which image to show
  function getWindowWidth() {
    const { innerWidth: width } = window;
    return width;
  }

  useEffect(() => {
    function handleResize() {
      setwindowWidth(getWindowWidth());
    }

    window.addEventListener("resize", handleResize);

    // "cleans up" event listener when component unmounts
    return () => window.removeEventListener("resize", handleResize);
  });

  return (
    <section className="vacation-mode-section">
      <img
        className="vacation-mode-hero-banner"
        src={
          windowWidth < 900
            ? "../../assets/images/vacation-hero-mobile.png"
            : "../../assets/images/vacation-hero-desktop.png"
        }
        alt="Vacation Mode Hero Banner"
      />
      <h3 className="vacation-mode-message">{vacationModeMessage}</h3>
    </section>
  );
}

export default VacationModeComponent;
