import React from 'react'

const useViewportType = (breakpoint: number): boolean => {
  const [windowWidth, setWindowWidth] = React.useState<number>(0)

  React.useEffect(() => {
    setWindowWidth((window.innerWidth))

    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', updateWindowWidth)

    return () => window.removeEventListener('resize', updateWindowWidth)
  }, [])

  return windowWidth < breakpoint
}

export default useViewportType