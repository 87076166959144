import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CreatePrint from "../../components/AdminPageComponents/CreatePrint";
import CreateClothing from "../../components/AdminPageComponents/CreateClothing";
import CreateStickersPins from "../../components/AdminPageComponents/CreateStickersPins";
import CreateHero from "../../components/AdminPageComponents/CreateHero";
import EditPrint from "../../components/AdminPageComponents/EditPrint";
import EditClothing from "../../components/AdminPageComponents/EditClothing";
import EditStickersPins from "../../components/AdminPageComponents/EditStickersPins";
import EditHero from "../../components/AdminPageComponents/EditHero";
import DeletePrint from "../../components/AdminPageComponents/DeletePrint";
import DeleteClothing from "../../components/AdminPageComponents/DeleteClothing";
import DeleteStickersPins from "../../components/AdminPageComponents/DeleteStickersPins";
import HotPrint from "../../components/AdminPageComponents/HotPrint";
import HotClothing from "../../components/AdminPageComponents/HotClothing";
import HotStickersPins from "../../components/AdminPageComponents/HotStickersPins";
import EditVacationModeComponent from "../../components/AdminPageComponents/EditVacationModeComponent";
import { getUser } from "../../utils/Session";
import "./style.css";

function AdminPage() {
  const history = useHistory();
  // state for displaying admin page links
  const [display, setDisplay] = useState<string>("adminLinks");

  useEffect(() => {
    if (getUser()) {
      if (getUser().role !== "admin") {
        history.push("/");
      }
    } else {
      history.push("/");
    }
  }, []);

  // determines what component to render based on user selection
  const switchDisplay = () => {
    // default return on page load; all admin links
    if (display === "adminLinks") {
      return (
        <section className="adminLinksSection">
          <h1 className="helloBeks">Hiya, Bonks</h1>

          {/* links for creating content */}
          <h3 className="adminCreateLinksHeading">Create</h3>
          <ul className="adminCreateLinks">
            <li className="adminLink" onClick={() => setDisplay("createPrint")}>
              Create Print
            </li>
            <li
              className="adminLink"
              onClick={() => setDisplay("createClothing")}
            >
              Create Clothing
            </li>
            <li
              className="adminLink"
              onClick={() => setDisplay("createStickersPins")}
            >
              Create Merch
            </li>
            <li className="adminLink" onClick={() => setDisplay("createHero")}>
              Create Homepage Hero Image
            </li>
          </ul>

          {/* links for editing content */}
          <h3 className="adminEditLinksHeading">Edit</h3>
          <ul className="adminEditLinks">
            <li className="adminLink" onClick={() => setDisplay("editPrint")}>
              Edit Print
            </li>
            <li
              className="adminLink"
              onClick={() => setDisplay("editClothing")}
            >
              Edit Clothing
            </li>
            <li
              className="adminLink"
              onClick={() => setDisplay("editStickersPins")}
            >
              Edit Merch
            </li>
            {/* <li className='adminLink' onClick={() => setDisplay('editHero')}>Edit Homepage Hero Image</li> */}
          </ul>

          {/* links for deleting content */}
          <h3 className="adminDeleteLinksHeading">Delete</h3>
          <ul className="adminDeleteLinks">
            <li className="adminLink" onClick={() => setDisplay("deletePrint")}>
              Delete Print
            </li>
            <li
              className="adminLink"
              onClick={() => setDisplay("deleteClothing")}
            >
              Delete Clothing
            </li>
            <li
              className="adminLink"
              onClick={() => setDisplay("deleteStickersPins")}
            >
              Delete Merch
            </li>
          </ul>

          {/* links for selecting what's hot items */}
          <h3 className="adminWhatsHotLinksHeading">What's Hot</h3>
          <ul className="adminWhatsHotLinks">
            <li className="adminLink" onClick={() => setDisplay("hotPrint")}>
              Select Hot Print
            </li>
            <li className="adminLink" onClick={() => setDisplay("hotClothing")}>
              Select Hot Clothing
            </li>
            <li
              className="adminLink"
              onClick={() => setDisplay("hotStickersPins")}
            >
              Select Merch
            </li>
          </ul>
          <h3 className="adminVacationModeHeading">Vacation Mode</h3>
          <ul className="adminVacationModeLink">
            <li
              className="adminLink"
              onClick={() => setDisplay("vacationMode")}
            >
              Set Vacation Mode
            </li>
          </ul>
        </section>
      );
    } else if (display === "createPrint") {
      return (
        <>
          <h4 className="adminBreadcrumbs">
            <span
              onClick={() => setDisplay("adminLinks")}
              style={{ cursor: "pointer" }}
            >
              Admin Homepage
            </span>{" "}
            {">"} Create Print
          </h4>
          <CreatePrint />
        </>
      );
    } else if (display === "createClothing") {
      return (
        <>
          <h4 className="adminBreadcrumbs">
            <span
              onClick={() => setDisplay("adminLinks")}
              style={{ cursor: "pointer" }}
            >
              Admin Homepage
            </span>{" "}
            {">"} Create Clothing
          </h4>
          <CreateClothing />
        </>
      );
    } else if (display === "createStickersPins") {
      return (
        <>
          <h4 className="adminBreadcrumbs">
            <span
              onClick={() => setDisplay("adminLinks")}
              style={{ cursor: "pointer" }}
            >
              Admin Homepage
            </span>{" "}
            {">"} Create Sticker/Pin
          </h4>
          <CreateStickersPins />
        </>
      );
    } else if (display === "createHero") {
      return (
        <>
          <h4 className="adminBreadcrumbs">
            <span
              onClick={() => setDisplay("adminLinks")}
              style={{ cursor: "pointer" }}
            >
              Admin Homepage
            </span>{" "}
            {">"} Create Hero Image
          </h4>
          <CreateHero />
        </>
      );
    } else if (display === "editPrint") {
      return (
        <>
          <h4 className="adminBreadcrumbs">
            <span
              onClick={() => setDisplay("adminLinks")}
              style={{ cursor: "pointer" }}
            >
              Admin Homepage
            </span>{" "}
            {">"} Edit Print
          </h4>
          <EditPrint />
        </>
      );
    } else if (display === "editClothing") {
      return (
        <>
          <h4 className="adminBreadcrumbs">
            <span
              onClick={() => setDisplay("adminLinks")}
              style={{ cursor: "pointer" }}
            >
              Admin Homepage
            </span>{" "}
            {">"} Edit Clothing
          </h4>
          <EditClothing />
        </>
      );
    } else if (display === "editStickersPins") {
      return (
        <>
          <h4 className="adminBreadcrumbs">
            <span
              onClick={() => setDisplay("adminLinks")}
              style={{ cursor: "pointer" }}
            >
              Admin Homepage
            </span>{" "}
            {">"} Edit Sticker/Pin
          </h4>
          <EditStickersPins />
        </>
      );
    } else if (display === "editHero") {
      return (
        <>
          <h4 className="adminBreadcrumbs">
            <span
              onClick={() => setDisplay("adminLinks")}
              style={{ cursor: "pointer" }}
            >
              Admin Homepage
            </span>{" "}
            {">"} Edit Hero Image
          </h4>
          <EditHero />
        </>
      );
    } else if (display === "deletePrint") {
      return (
        <>
          <h4 className="adminBreadcrumbs">
            <span
              onClick={() => setDisplay("adminLinks")}
              style={{ cursor: "pointer" }}
            >
              Admin Homepage
            </span>{" "}
            {">"} Delete Print
          </h4>
          <DeletePrint />
        </>
      );
    } else if (display === "deleteClothing") {
      return (
        <>
          <h4 className="adminBreadcrumbs">
            <span
              onClick={() => setDisplay("adminLinks")}
              style={{ cursor: "pointer" }}
            >
              Admin Homepage
            </span>{" "}
            {">"} Delete Clothing
          </h4>
          <DeleteClothing />
        </>
      );
    } else if (display === "deleteStickersPins") {
      return (
        <>
          <h4 className="adminBreadcrumbs">
            <span
              onClick={() => setDisplay("adminLinks")}
              style={{ cursor: "pointer" }}
            >
              Admin Homepage
            </span>{" "}
            {">"} Delete Sticker/Pin
          </h4>
          <DeleteStickersPins />
        </>
      );
    } else if (display === "hotPrint") {
      return (
        <>
          <h4 className="adminBreadcrumbs">
            <span
              onClick={() => setDisplay("adminLinks")}
              style={{ cursor: "pointer" }}
            >
              Admin Homepage
            </span>{" "}
            {">"} Select Hot Print
          </h4>
          <HotPrint />
        </>
      );
    } else if (display === "hotClothing") {
      return (
        <>
          <h4 className="adminBreadcrumbs">
            <span
              onClick={() => setDisplay("adminLinks")}
              style={{ cursor: "pointer" }}
            >
              Admin Homepage
            </span>{" "}
            {">"} Select Hot Clothing
          </h4>
          <HotClothing />
        </>
      );
    } else if (display === "hotStickersPins") {
      return (
        <>
          <h4 className="adminBreadcrumbs">
            <span
              onClick={() => setDisplay("adminLinks")}
              style={{ cursor: "pointer" }}
            >
              Admin Homepage
            </span>{" "}
            {">"} Select Hot Sticker/Pin
          </h4>
          <HotStickersPins />
        </>
      );
    } else if (display === "vacationMode") {
      return (
        <>
          <h4 className="adminBreadcrumbs">
            <span
              onClick={() => setDisplay("adminLinks")}
              style={{ cursor: "pointer" }}
            >
              Admin Homepage
            </span>{" "}
            {">"} Set Vacation Mode
          </h4>
          <EditVacationModeComponent />
        </>
      );
    }
  };

  return (
    <>
      {switchDisplay()}
    </>
  );
}

export default AdminPage;
