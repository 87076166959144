import React, { useEffect } from 'react'

const Gallery = ({ children }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  return <>{children}</>
}

export default Gallery
