import React, { CSSProperties, useState, useRef, useEffect } from 'react'
import Hamburger from './Hamburger'
import CartIcon from './CartIcon'
import HomeIcon from './HomeIcon'
import { COLORS } from '../../../constants/theme'

const NavMobile = (): React.ReactElement => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const [navPosition, setNavPosition] = useState<string>('0px')

  let prevScrollPos: number = window.scrollY
  window.addEventListener('scroll', () => {
    let currentScrollPos: number = window.scrollY
    if (prevScrollPos > currentScrollPos) {
      setNavPosition('0px')
    } else if (isOpen || currentScrollPos < 50) {
      setNavPosition('0px')
    } else {
      setNavPosition('-110px')
    }
  })

  const styles: { [key: string]: CSSProperties } = {
    nav: {
      top: navPosition,
      transition: '.25s',
      backgroundColor: COLORS.offWhite,
      width: '100vw',
      display: 'flex',
      justifyContent: 'space-between'
    },
    rightSideNavItems: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '8px 0'
    }
  }

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: { target: any }) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setOpen(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <nav style={styles.nav} ref={wrapperRef}>
      <HomeIcon />

      {/* wrapper for cart icon and hamburger icon */}
      <div style={styles.rightSideNavItems}>
        <CartIcon />

        <Hamburger isOpen={isOpen} setOpen={setOpen} />
      </div>
    </nav>
  )
}

export default NavMobile
