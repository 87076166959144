export const getRandomDoodles = () => {
    const doodlePath = '../../assets/images/doodles/doodle_'

    const doodleNames = [
        'cat.svg',
        'fern.svg',
        'flower.svg',
        'fox.svg',
        'frog.svg',
        'jumping_fox.svg',
        'many_eyed_cat.svg',
        'moth.svg',
        'shrooms.svg',
        'sun.svg',
        'wizard.svg'
    ]
    const randomDoodles = []

    for (let i = 0; i < 3; i++) {
        const doodleIdx = Math.floor(Math.random() * doodleNames.length)

        randomDoodles.push(`${doodlePath}${doodleNames[doodleIdx]}`)

        // remove item from doodleNames array to avoid duplicates
        doodleNames.splice(doodleIdx, 1)
    }

    return randomDoodles
}
