import React, { CSSProperties, useState } from 'react'
import CartIcon from './CartIcon'
import HomeIcon from './HomeIcon'
import { COLORS, FONTS, FONT_WEIGHTS } from '../../../constants/theme'
import useViewportType from '../../../utils/useViewportType'

const NavDesktop = () => {
  const isMedScreen = useViewportType(1050)
  const [navPosition, setNavPosition] = useState<string>('0px')

  let prevScrollPos: number = window.scrollY
  window.addEventListener('scroll', () => {
    let currentScrollPos: number = window.scrollY
    if (prevScrollPos > currentScrollPos) {
      setNavPosition('0px')
    } else if (currentScrollPos < 50) {
      setNavPosition('0px')
    } else {
      setNavPosition('-110px')
    }
  })

  const styles: { [key: string]: CSSProperties } = {
    nav: {
      top: navPosition,
      transition: '.25s',
      backgroundColor: COLORS.offWhite,
      height: '140px',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 40px'
    },
    linksWrapper: {
      width: '100%',
      maxWidth: '900px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0 75px'
    },
    link: {
      fontSize: isMedScreen ? '1.35rem' : '1.75rem',
      fontFamily: FONTS.serif,
      color: COLORS.medBlue,
      fontWeight: FONT_WEIGHTS.mediumLight,
      textDecoration: 'none'
    },
    rightSideNavItems: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '8px 0'
    }
  }

  return (
    <nav style={styles.nav}>
      <HomeIcon />

      <div style={styles.linksWrapper}>
        <a style={styles.link} href='/print-gallery'>
          Art Prints
        </a>
        <a style={styles.link} href='/clothing-gallery'>
          Clothing
        </a>
        <a style={styles.link} href='/merch-gallery'>
          Merch
        </a>
        <a style={styles.link} href='/commissions'>
          Commissions
        </a>
        <a style={styles.link} href='/about'>
          About
        </a>
      </div>

      {/* wrapper for cart icon */}
      <div style={styles.rightSideNavItems}>
        <CartIcon />
      </div>
    </nav>
  )
}

export default NavDesktop
