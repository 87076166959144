import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "./style.css";
import SinglePrintEdit from "../SinglePrintEdit";

interface Print {
  id: string;
  name: string;
  dimensions: string;
  smallPrice: number;
  mediumPrice: number;
  largePrice: number | null;
  description: string;
  smallVariantId: string;
  mediumVariantId: string;
  largeVariantId: string | null;
  location: string;
  candidLocation: string;
}

function EditPrint() {
  const [galleryImages, setGalleryImages] = useState<any>([]);

  // used to track whether a specific item is being edited
  const [editMode, setEditMode] = useState<boolean>(false);

  // passed to SinglePrintEdit component
  const [print, setPrint] = useState<Print>({
    id: "",
    name: "",
    dimensions: "",
    smallPrice: 0,
    mediumPrice: 0,
    largePrice: null,
    description: "",
    smallVariantId: "",
    mediumVariantId: "",
    largeVariantId: null,
    location: "",
    candidLocation: "",
  });

  useEffect(() => {
    axios
      .get("/api/prints")
      .then((res) => {
        setGalleryImages(res.data);
      })
      .catch((err) => console.error(err));
  }, []);

  const handleEdit = (
    id: string,
    name: string,
    dimensions: string,
    smallPrice: number,
    mediumPrice: number,
    largePrice: number | null,
    description: string,
    smallVariantId: string,
    mediumVariantId: string,
    largeVariantId: string | null,
    location: string,
    candidLocation: string
  ) => {
    setPrint({
      id,
      name,
      dimensions,
      smallPrice,
      mediumPrice,
      largePrice: largePrice || null,
      description,
      smallVariantId,
      mediumVariantId,
      largeVariantId: largeVariantId || null,
      location,
      candidLocation,
    });
    setEditMode(true);
  };

  return (
    <>
      {!editMode ? (
        <section className="editWrapper">
          <h1 className="editHeading">Select Print to Edit</h1>
          <div className="galleryContentWrapper">
            {galleryImages.map((el: any) => {
              return (
                <div key={el.id} className="galleryItemWrapper">
                  <div
                    className="galleryImage"
                    style={{ backgroundImage: `url(${el.location})` }}
                    onClick={() =>
                      handleEdit(
                        el.id,
                        el.name,
                        el.dimensions,
                        el.smallPrice,
                        el.mediumPrice,
                        el?.largePrice || null,
                        el.description,
                        el.smallVariantId,
                        el.mediumVariantId,
                        el?.largeVariantId || null,
                        el.location,
                        el.candidLocation
                      )
                    }
                  ></div>
                  <h3
                    className="galleryArtName"
                    onClick={() =>
                      handleEdit(
                        el.id,
                        el.name,
                        el.dimensions,
                        el.smallPrice,
                        el.mediumPrice,
                        el?.largePrice || null,
                        el.description,
                        el.smallVariantId,
                        el.mediumVariantId,
                        el?.largeVariantId || null,
                        el.location,
                        el.candidLocation
                      )
                    }
                  >
                    {el.name}
                  </h3>
                  <h3 className="galleryArtPrice">From ${el.smallPrice}</h3>
                </div>
              );
            })}
          </div>
        </section>
      ) : (
        <SinglePrintEdit {...print} />
      )}
    </>
  );
}

export default EditPrint;
