import axios from "axios";

export const createPrintfulOrder = async () => {

    // shipping details we get from stripe webhook
    const shippingDetails = await axios
        .get("/api/printful")
        .then((res) => formatShipping(res.data))
        .catch((err) => console.error(`Error getting shipping details: ${err}`));

    // prints stored when clicking checkout on cart page
    const prints = JSON.parse(sessionStorage.getItem("prints") || "");

    // headers config with printful bearer token
    const config = {
        headers: { Authorization: 'Bearer jGK3XlhOTOKgkNxTPiiNTwmLtpZH8qh9xBzfDN49' }
    }

    if (shippingDetails) {
        const printData = await formatPrintData(prints)
        try {
            // data body to be sent to printful
            const data = {
                recipient: shippingDetails,
                items: printData
            }

            // create printful order
            await axios.post('https://api.printful.com/orders?confirm=1', data, config)
                .catch(err => console.error(err))
        } catch (e) {
            console.error('There was an issue creating the printful order: ', e)
        }
    } else {
        console.error('Shipping details not found.')
    }
};

const formatShipping = (data) => {
    return {
        name: data.shippingDetails.name,
        address1: data.shippingDetails.address.line1,
        address2: data.shippingDetails.address?.line2 || null,
        city: data.shippingDetails.address.city,
        state_code: data.shippingDetails.address.state,
        country_code: 'US',
        zip: data.shippingDetails.address.postal_code
    }
}

const formatPrintData = async (printData) => {
    return printData.map(print => {
        return {
            external_variant_id: print.variantId,
            quantity: print.quantity
        }
    })
}
