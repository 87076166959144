import React, { CSSProperties } from 'react'
import PropTypes from 'prop-types'
import useViewportType from '../../utils/useViewportType'

interface Props {
  location: string
}

const styles: { [key: string]: CSSProperties } | any = {
  wrapper: (isMobile: boolean) => ({
    position: 'relative',
    maxHeight: isMobile ? 'none' : '60vw',
    overflow: 'hidden',
  }),
  img: (isMobile: boolean) => ({
    width: '100vw',
    clipPath: isMobile ? 'none' : 'inset(20vh 0)',
    marginTop: isMobile ? 'auto' : '-20vh'
  }),
  wave: {
    width: '100vw',
    position: 'absolute',
    bottom: '-1px',
    left: 0
  }
}

const Hero = ({ location }: Props): React.ReactElement => {
  const isMobile = useViewportType(700)

  return (
    <section style={styles.wrapper(isMobile)}>
      <img
        src={location}
        alt='Hero - Click to visit prints gallery'
        style={styles.img(isMobile)}
      />
      <img
        src='../../assets/images/wave_hero.svg'
        alt='Wave svg'
        style={styles.wave}
      />
    </section>
  )
}

Hero.propTypes = {
  location: PropTypes.string.isRequired
}

export default Hero
