import React from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";

interface Print {
  id: string;
  name: string;
  dimensions: string;
  smallPrice: number;
  mediumPrice: number;
  largePrice: number | null;
  description: string;
  smallVariantId: string;
  mediumVariantId: string;
  largeVariantId: string | null;
  location: string;
  candidLocation: string;
}

function SinglePrintEdit(props: Print) {
  const printName = useFormInput(props.name);
  const dimensions = useFormInput(props.dimensions);
  const smallPrice = useFormInput(props.smallPrice);
  const mediumPrice = useFormInput(props.mediumPrice);
  const largePrice = useFormInput(props.largePrice);
  const description = useFormInput(props.description);
  const smallVariantId = useFormInput(props.smallVariantId);
  const mediumVariantId = useFormInput(props.mediumVariantId);
  const largeVariantId = useFormInput(props.largeVariantId);

  const handleSubmit = (e: Event) => {
    e.preventDefault();

    axios
      .put(`/api/prints/update/id/${props.id}`, {
        name: printName.value,
        dimensions: dimensions.value,
        smallPrice: smallPrice.value,
        mediumPrice: mediumPrice.value,
        largePrice: largePrice.value,
        description: description.value,
        smallVariantId: smallVariantId.value,
        mediumVariantId: mediumVariantId.value,
        largeVariantId: largeVariantId.value,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          iconColor: "#41F2A",
          title: "<span>Item updated successfully!</span>",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: "error",
          title: "<span>shiiiiiit, that didn't work</span>",
          showConfirmButton: false,
          timer: 2500,
        });
      });
  };

  return (
    <section className="singleItemEditWrapper">
      <form encType="multipart/form-data" className="createPrintForm">
        <h1>Edit {props.name}</h1>

        <h3 className="createPrintName">Print Name</h3>
        <textarea
          {...printName}
          className="editItemName editTextarea"
          placeholder="The Illest Print"
        ></textarea>

        {/* do we need this? */}
        {/* <h3 className='createPrintName'>Dimensions</h3>
                <textarea
                    {...dimensions}
                    className='editItemName editTextarea'
                    placeholder='69x420'
                ></textarea> */}

        <h3 className="createPrintSmallPrice">Small Price</h3>
        <textarea
          {...smallPrice}
          className="editItemPrice editTextarea"
          placeholder="69"
        ></textarea>

        <h3 className="createPrintMediumPrice">Medium Price</h3>
        <textarea
          {...mediumPrice}
          className="editItemPrice editTextarea"
          placeholder="420"
        ></textarea>

        {props.largePrice && (
          <>
            <h3 className="createPrintLargePrice">LargePrice</h3>
            <textarea
              {...largePrice}
              className="editItemPrice editTextarea"
              placeholder="69420"
            ></textarea>
          </>
        )}

        <h3 className="createMerchDesc">Description</h3>
        <textarea
          {...description}
          className="createMerchDescInput"
          placeholder="this shit is LIT"
        ></textarea>

        <h3 className="createPrintName">Small Variant Id</h3>
        <textarea
          {...smallVariantId}
          className="editItemName editTextarea"
          placeholder="dont mess this up"
        ></textarea>

        <h3 className="createPrintName">Medium Variant Id</h3>
        <textarea
          {...mediumVariantId}
          className="editItemName editTextarea"
          placeholder="please dont mess this up"
        ></textarea>

        {props.largeVariantId && (
          <>
            <h3 className="editPrintName">Large Variant Id</h3>
            <textarea
              {...largeVariantId}
              className="editItemName editTextarea"
              placeholder="omg PLEASE"
            ></textarea>
          </>
        )}

        <button className="submitBtn" onClick={(e: any) => handleSubmit(e)}>
          Save Print
        </button>
      </form>
    </section>
  );
}

const useFormInput = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  return {
    value,
    onChange: handleChange,
  };
};

export default SinglePrintEdit;
