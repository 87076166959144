import React from 'react'
import { useEffect } from 'react'
import ClothingGalleryComponent from '../components/ClothingGalleryComponent'

function Clothing() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <ClothingGalleryComponent />
}

export default Clothing
