import React from 'react'
import { useEffect } from 'react'
import PrintsGalleryComponent from '../components/PrintsGalleryComponent'

function PrintsGallery() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <PrintsGalleryComponent />
}

export default PrintsGallery
