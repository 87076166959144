import React from 'react'
import { useEffect } from 'react'
import SuccessfulOrderComponent from '../components/SuccessfulOrderComponent'

function SuccessfulOrder() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <SuccessfulOrderComponent />
}

export default SuccessfulOrder
