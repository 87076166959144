import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { getUser, setUserSession } from "../../utils/Session";
import Swal from "sweetalert2";
import "./style.css";

function Login() {
  // variables for input values (state handling in useFormInput at bottom of page before export)
  const email: any = useFormInput("");
  const password: any = useFormInput("");

  const history = useHistory();

  useEffect(() => {
    // if user already logged in, push to homepage
    if (getUser() && getUser().role !== "guest") history.push("./");
    window.scroll(0, 0);
  }, []);

  const loginHandler = (e: any) => {
    e.preventDefault();

    axios
      .post("/api/users/login", {
        email: email.value,
        password: password.value,
      })
      .then((res: any) => {
        setUserSession(res.data.token, res.data.user);
        // remove guest id
        if (localStorage.getItem("id")) localStorage.removeItem("id");
        history.goBack();
        window.location.reload();
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "<span>Invalid email or password.</span>",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const showOrHidePassword = () => {
    const passwordEyeIcon: any = document.getElementById("loginEyeIcon");
    const passwordInput: any = document.getElementById("passwordInput");
    if (passwordInput.getAttribute("type") === "text") {
      passwordEyeIcon.setAttribute(
        "src",
        "https://cdn0.iconfinder.com/data/icons/feather/96/eye-16.png"
      );
      passwordInput.setAttribute("type", "password");
    } else if (passwordInput.getAttribute("type") === "password") {
      passwordEyeIcon.setAttribute(
        "src",
        "https://cdn0.iconfinder.com/data/icons/tidee-health/24/015_002_eye_closed_sleep_hide-16.png"
      );
      passwordInput.setAttribute("type", "text");
    }
  };

  return (
    <main className="loginMain">
      <h1 className="loginHeading">Log in</h1>
      <div className="loginFormWrapper">
        <form className="loginForm" onSubmit={loginHandler}>
          <h3 className="emailLoginHeading">Email</h3>
          <input
            className="emailLoginInput"
            {...email}
            autoFocus
            type="email"
            placeholder="Enter your email"
          />
          <div className="passwordLoginHeadingWrapper">
            <h3 className="passwordLoginHeading">Password</h3>
            <button type="button" id="loginEye" onClick={showOrHidePassword}>
              <img
                id="loginEyeIcon"
                src="https://cdn0.iconfinder.com/data/icons/feather/96/eye-16.png"
                alt="show/hide password"
              />
            </button>
          </div>
          <input
            className="passwordLoginInput"
            {...password}
            id="passwordInput"
            type="password"
            placeholder="Enter your password"
          ></input>
          <button className="loginBtn" type="submit">
            Log in
          </button>
        </form>
        <p className="login-signup-p">
          <a className="login-signup-a" href="/signup">
            Don't have an account? Sign up!
          </a>
        </p>
      </div>
    </main>
  );
}

const useFormInput = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  return {
    value,
    onChange: handleChange,
  };
};

export default Login;
