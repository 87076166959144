import React from 'react'
import { useHistory } from 'react-router'
import { useState, useEffect } from 'react'
import axios from 'axios'
import './style.css'
import { FONT_SIZES, LINE_HEIGHTS } from '../../../constants/theme'
import Loading from '../../../components/common/Loading'

const styles = {
  header: {
    fontSize: FONT_SIZES.desktopHeader
  },
  body: {
    fontSize: FONT_SIZES.desktopBody,
    lineHeight: LINE_HEIGHTS.desktopBody,
    maxWidth: '800px'
  }
}

function PrintsGalleryComponent() {
  const [galleryImages, setGalleryImages] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)

  const getPrints = async () => {
    setIsLoading(true)
    await axios.get('/api/prints').then((res) => {
      setGalleryImages(res.data)
    })
    setIsLoading(false)
  }

  useEffect(() => {
    getPrints()
  }, [])

  const history = useHistory()

  return (
    <section className='galleryWrapper'>
      <h1 style={styles.header} className='galleryHeading'>
        Art Prints
      </h1>
      <h3 style={styles.body} className='galleryDesc'>
        Prints of original artwork by Rebecca Stone, the creator of Enchanted
        Quill Artwork.{' '}
      </h3>
      {isLoading ? (
        <Loading />
      ) : (
        <div className='galleryContentWrapper'>
          {/* eventually will map from gallery table in database */}
          {galleryImages.map((el: any) => {
            return (
              <div key={el.id} className='galleryItemWrapper'>
                <div
                  className='galleryImage'
                  style={{ backgroundImage: 'url(' + el.location + ')' }}
                  onClick={() => history.push('image/' + el.id)}
                ></div>
                <h3
                  className='galleryArtName'
                  onClick={() => history.push('image/' + el.id)}
                >
                  {el.name}
                </h3>
                <h3 className='galleryArtPrice'>From ${el.smallPrice}</h3>
              </div>
            )
          })}
        </div>
      )}
    </section>
  )
}

export default PrintsGalleryComponent
