import React, { useEffect, useState } from "react";
import axios from "axios";
import SingleClothingEdit from "../SingleClothingEdit";

interface Clothing {
  id: string;
  printName: string;
  descName: string;
  price: number;
  description: string;
  primaryImage: string;
  secondaryImage: string;
  supplementaryImage: string | null | undefined;
}

function EditClothing() {
  const [galleryImages, setGalleryImages] = useState<any>([]);

  // used to track whether a specific item is being edited
  const [editMode, setEditMode] = useState<boolean>(false);

  // passed to SingleClothingEdit component
  const [clothing, setClothing] = useState<Clothing>({
    id: "",
    printName: "",
    descName: "",
    price: 0,
    description: "",
    primaryImage: "",
    secondaryImage: "",
    supplementaryImage: "",
  });

  useEffect(() => {
    axios
      .get("/api/clothing")
      .then((res) => {
        setGalleryImages(res.data);
      })
      .catch((err) => console.error(err));
  }, []);

  const handleEdit = (
    id: string,
    printName: string,
    descName: string,
    price: number,
    description: string,
    primaryImage: string,
    secondaryImage: string,
    supplementaryImage: string | null | undefined
  ) => {
    setClothing({
      id,
      printName,
      descName,
      price,
      description,
      primaryImage,
      secondaryImage,
      supplementaryImage,
    });
    setEditMode(true);
  };

  return (
    <>
      {!editMode ? (
        <section className="editWrapper">
          <h1 className="editHeading">Select Clothing to Edit</h1>
          <div className="galleryContentWrapper">
            {galleryImages.map((el: any) => {
              return (
                <div key={el.id} className="galleryItemWrapper">
                  <div
                    className="galleryImage"
                    style={{ backgroundImage: `url(${el.primaryImage})` }}
                    onClick={() =>
                      handleEdit(
                        el.id,
                        el.printName,
                        el.descName,
                        el.price,
                        el.description,
                        el.primaryImage,
                        el.secondaryImage,
                        el?.supplementaryImage
                      )
                    }
                  ></div>
                  <h3
                    className="galleryArtName"
                    onClick={() =>
                      handleEdit(
                        el.id,
                        el.printName,
                        el.descName,
                        el.price,
                        el.description,
                        el.primaryImage,
                        el.secondaryImage,
                        el?.supplementaryImage
                      )
                    }
                  >
                    {el.printName}
                  </h3>
                  <h3 className="galleryArtPrice">${el.price}</h3>
                </div>
              );
            })}
          </div>
        </section>
      ) : (
        <SingleClothingEdit {...clothing} />
      )}
    </>
  );
}

export default EditClothing;
