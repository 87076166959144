import React from 'react'
import { useEffect } from 'react'
import AboutSectionComponent from '../components/AboutComponents/AboutSectionComponent'
import InstagramComponent from '../components/AboutComponents/InstagramComponent'

function About() {
  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <>
      <AboutSectionComponent />
      <InstagramComponent />
    </>
  )
}

export default About
