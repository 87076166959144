import React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
// import Swal from "sweetalert2";
import "./style.css";
import SingleItemEdit from "../SingleItemEdit";

interface StickerPin {
  id: string;
  printName: string;
  descName: string;
  price: number;
  description: string;
  quantity: number;
}

function EditStickersPins() {
  const [galleryImages, setGalleryImages] = useState<any>([]);

  // used to track whether a specific item is being edited
  const [editMode, setEditMode] = useState<boolean>(false);

  // passed to SingleItemEdit component
  const [stickerPin, setStickerPin] = useState<StickerPin>({
    id: "",
    printName: "",
    descName: "",
    price: 0,
    description: "",
    quantity: 0,
  });

  useEffect(() => {
    axios
      .get("/api/merch")
      .then((res) => {
        setGalleryImages(res.data);
      })
      .catch((err) => console.error(err));
  }, []);

  const history = useHistory();

  const handleEdit = (
    id: string,
    printName: string,
    descName: string,
    price: number,
    description: string,
    quantity: number
  ) => {
    setStickerPin({
      id,
      printName,
      descName,
      price,
      description,
      quantity,
    });
    setEditMode(true);
  };

  return (
    <>
      {!editMode ? (
        <section className="editWrapper">
          <h1 className="editHeading">Select Merch to Edit</h1>
          <div className="galleryContentWrapper">
            {/* eventually will map from gallery table in database */}
            {galleryImages.map((el: any) => {
              return (
                <div key={el.id} className="galleryItemWrapper">
                  <div
                    className="galleryImage"
                    style={{ backgroundImage: "url(" + el.primaryImage + ")" }}
                    onClick={() =>
                      handleEdit(
                        el.id,
                        el.printName,
                        el.descName,
                        el.price,
                        el.description,
                        el.quantity
                      )
                    }
                  ></div>
                  <h3
                    className="galleryArtName"
                    onClick={() =>
                      handleEdit(
                        el.id,
                        el.printName,
                        el.descName,
                        el.price,
                        el.description,
                        el.quantity
                      )
                    }
                  >
                    {el.printName}
                  </h3>
                  <h3 className="galleryArtPrice">${el.price}</h3>
                </div>
              );
            })}
          </div>
        </section>
      ) : (
        <SingleItemEdit {...stickerPin} />
      )}
    </>
  );
}

export default EditStickersPins;
