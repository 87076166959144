import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import animationData from '../common/animations/loading-animation.json'

const Loading = () => {

  return (
    <Player
      autoplay
      loop
      src={animationData}
      style={{ height: '300px', width: '300px', marginBottom: '50px' }} 
    />

  )
}

export default Loading
