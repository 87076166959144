import React from "react";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "./style.css";

interface Window {
  cloudinary: any;
  location: any;
}

declare var window: Window;

function CreateHero() {
  const heroName = useFormInput("");

  //* Hero image Cloudinary state
  // true if uploading hero image was successful
  const [heroUploadSuccess, setHeroUploadSuccess] = useState<boolean>(false);
  // location of uploaded image to be stored in database
  const [heroCloudinaryLocation, setHeroCloudinaryLocation] =
    useState<string>();
  // hero filename to be displayed once image is uploaded
  const [heroFilename, setHeroFilename] = useState<string>();
  // upload hero button text
  const [uploadHeroBtnText, setUploadHeroBtnText] =
    useState<string>("Upload Hero Image");

  //* Print image Cloudinary upload widget
  // widget for uploading Hero images to Cloudinary
  let heroDownloadWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: "rflctveq",
      uploadPreset: "qirahbiz",
      folder: "HomepageHero",
      sources: ["local"],
    },
    (error: any, result: any) => {
      if (result.event === "success") {
        setHeroUploadSuccess(true);
        setHeroFilename(
          result.info.original_filename + "." + result.info.format
        );
        setHeroCloudinaryLocation(result.info.secure_url);
      }

      if (result.event === "close") setUploadHeroBtnText("Upload Hero Image");
    }
  );
  // function for opening upload widget for Prints
  const showHeroDownloadWidget = (e: any) => {
    setUploadHeroBtnText("Loading...");
    e.preventDefault();
    heroDownloadWidget.open();
  };

  // handle user attempt to submit new print
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (heroName.value === "") {
      Swal.fire({
        icon: "warning",
        title: "<span>Please enter a hero name.</span>",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    } else if (!heroUploadSuccess) {
      Swal.fire({
        icon: "warning",
        title: "<span>Please upload a hero image",
      });
    } else {
      axios
        .post("/api/hero/create", {
          name: heroName.value,
          location: heroCloudinaryLocation,
        })
        .then((res) => {
          Swal.fire({
            icon: "success",
            iconColor: "#41F2A0",
            title: "<span>Hero created successfully!</span>",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <section className="createPrintSection">
      <form encType="multipart/form-data" className="createPrintForm">
        <h1>Create Hero</h1>

        <h3 className="createPrintName">Hero Name</h3>
        <input {...heroName} className="createPrintNameInput"></input>

        {!heroUploadSuccess ? (
          // upload print image
          <button
            className="uploadBtn"
            onClick={(e: any) => showHeroDownloadWidget(e)}
          >
            {uploadHeroBtnText}
          </button>
        ) : (
          // show user file upload successful
          <div className="successfulUploadDiv">
            <img
              src="../../assets/images/success-svgrepo-com.svg"
              className="successIcon"
              alt="Green check"
            />
            <h4>
              <span>{heroFilename}</span> uploaded successfully!
            </h4>
          </div>
        )}

        <button className="submitBtn" onClick={(e: any) => handleSubmit(e)}>
          Create new hero
        </button>
      </form>
    </section>
  );
}

const useFormInput = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  return {
    value,
    onChange: handleChange,
  };
};

export default CreateHero;
