import React from "react";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "./style.css";

interface Window {
  cloudinary: any;
  location: any;
}

declare var window: Window;

function CreatePrint() {
  const printName = useFormInput("");
  const printDesc = useFormInput("");
  const smallSizePrice = useFormInput("");
  const mediumSizePrice = useFormInput("");
  const largeSizePrice = useFormInput("");
  const smallVariantIdInput = useFormInput("");
  const mediumVariantIdInput = useFormInput("");
  const largeVariantIdInput = useFormInput("");
  const [printDimensions, setPrintDimensions] = useState({
    dimensions: "square",
    squareIsChecked: true,
    portraitIsChecked: false,
  });

  //* Print image Cloudinary state
  // true if uploading print image was successful
  const [printUploadSuccess, setPrintUploadSuccess] = useState<boolean>(false);
  // location of uploaded image to be stored in database
  const [printCloudinaryLocation, setPrintCloudinaryLocation] =
    useState<string>();
  // print filename to be displayed once image is uploaded
  const [printFilename, setPrintFilename] = useState<string>();
  // upload print button text
  const [uploadPrintBtnText, setUploadPrintBtnText] =
    useState<string>("Upload Print Image");

  //* Candid image Cloudinary state
  // true if uploading candid image was successful
  const [candidUploadSuccess, setCandidUploadSuccess] =
    useState<boolean>(false);
  // location of uploaded candid image to be stored in database
  const [candidCloudinaryLocation, setCandidCloudinaryLocation] =
    useState<string>();
  // candid filename to be displayed once image is uploaded
  const [candidFilename, setCandidFilename] = useState<string>();
  // upload candid button text
  const [uploadCandidBtnText, setUploadCandidBtnText] = useState<string>(
    "Upload Candid Image"
  );

  //* Display Print image Cloudinary upload widget
  // widget for uploading Print images to Cloudinary
  let printDownloadWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: "rflctveq",
      uploadPreset: "qirahbiz",
      folder: "Prints",
      sources: ["local"],
    },
    (error: any, result: any) => {
      if (result.event === "success") {
        setPrintUploadSuccess(true);
        setPrintFilename(
          result.info.original_filename + "." + result.info.format
        );
        setPrintCloudinaryLocation(result.info.secure_url);
      }

      if (result.event === "close") setUploadPrintBtnText("Upload print image");
    }
  );
  // function for opening upload widget for Prints
  const showPrintDownloadWidget = (e: any) => {
    setUploadPrintBtnText("Loading...");
    e.preventDefault();
    printDownloadWidget.open();
  };

  //* Candid image Cloudinary upload widget
  // widget for uploading Candid images to Cloudinary
  let candidDownloadWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: "rflctveq",
      uploadPreset: "qirahbiz",
      folder: "Prints",
      sources: ["local"],
    },
    (error: any, result: any) => {
      if (result.event === "success") {
        setCandidUploadSuccess(true);
        setCandidFilename(
          result.info.original_filename + "." + result.info.format
        );
        setCandidCloudinaryLocation(result.info.secure_url);
      }

      if (result.event === "close")
        setUploadCandidBtnText("Upload candid image");
    }
  );
  // function for opening upload widget for candid images
  const showCandidDownloadWidget = (e: any) => {
    setUploadCandidBtnText("Loading...");
    e.preventDefault();
    candidDownloadWidget.open();
  };

  const handleDimensionsCheckbox = () => {
    if (printDimensions.dimensions === "square") {
      setPrintDimensions({
        dimensions: "portrait",
        squareIsChecked: false,
        portraitIsChecked: true,
      });
    } else {
      setPrintDimensions({
        dimensions: "square",
        squareIsChecked: true,
        portraitIsChecked: false,
      });
    }
  };

  // handle user attempt to submit new print
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (printName.value === "") {
      Swal.fire({
        icon: "warning",
        title: "<span>Please enter a print name.</span>",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    } else if (smallSizePrice.value === "" || smallSizePrice.value < 0.01) {
      Swal.fire({
        icon: "warning",
        title:
          "<span>Please enter a small size price greater than zero.</span>",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    } else if (mediumSizePrice.value === "" || mediumSizePrice.value < 0.01) {
      Swal.fire({
        icon: "warning",
        title:
          "<span>Please enter a medium size price greater than zero.</span>",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    } else if (
      (largeSizePrice.value === "" || largeSizePrice.value < 0.01) &&
      printDimensions.squareIsChecked
    ) {
      Swal.fire({
        icon: "warning",
        title:
          "<span>Please enter a large size price greater than zero.</span>",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    } else if (smallVariantIdInput.value === "") {
      Swal.fire({
        icon: "warning",
        title: "<span>Please enter a small variant id.</span>",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    } else if (mediumVariantIdInput.value === "") {
      Swal.fire({
        icon: "warning",
        title: "<span>Please enter a medium variant id.</span>",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    } else if (
      largeVariantIdInput.value === "" &&
      printDimensions.squareIsChecked
    ) {
      Swal.fire({
        icon: "warning",
        title: "<span>Please enter a large variant id.</span>",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    } else if (!printUploadSuccess) {
      Swal.fire({
        icon: "warning",
        title: "<span>Please upload a print image.</span>",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    } else if (!candidUploadSuccess) {
      Swal.fire({
        icon: "warning",
        title: "<span>Please upload a candid image.</span>",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    } else {
      let sanitizedLargePrice = null;
      // set largePrice value based on dimensions and value entered
      if (
        parseInt(largeSizePrice.value) > 0.01 &&
        printDimensions.squareIsChecked
      ) {
        sanitizedLargePrice = parseFloat(largeSizePrice.value);
      } else {
        sanitizedLargePrice = null;
      }
      axios
        .post("/api/prints/create", {
          name: printName.value,
          dimensions: printDimensions.dimensions,
          smallPrice: parseFloat(smallSizePrice.value),
          mediumPrice: parseFloat(mediumSizePrice.value),
          largePrice: parseFloat(largeSizePrice.value),
          description: printDesc.value,
          smallVariantId: smallVariantIdInput.value,
          mediumVariantId: mediumVariantIdInput.value,
          largeVariantId: largeVariantIdInput.value,
          location: printCloudinaryLocation,
          candidLocation: candidCloudinaryLocation,
        })
        .then((res) => {
          Swal.fire({
            icon: "success",
            iconColor: "#41F2A0",
            title: "<span>Print created successfully!</span>",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <section className="createPrintSection">
      <form encType="multipart/form-data" className="createPrintForm">
        <h1>Create a Print</h1>

        <h3 className="createPrintName">Print Name</h3>
        <input {...printName} className="createPrintNameInput"></input>

        <h3 className="createPrintDesc">Description</h3>
        <textarea
          {...printDesc}
          maxLength={250}
          className="createPrintDescInput"
          placeholder="STOP RIGHT THERE. This description does not currently appear anywhere on the site. Do not waste your time filling this out unless you wanna leave a little easter egg for anyone savvy enough to hit our endpoints. ;)"
        ></textarea>

        <h3 className="createPrintDimensions">Print dimensions</h3>
        <div className="createPrintDimensionsCheckboxWrapper">
          <div>
            <input
              type="checkbox"
              value="square"
              checked={printDimensions.squareIsChecked}
              onClick={() => handleDimensionsCheckbox()}
            />
            <label>square</label>
          </div>
          <div>
            <input
              type="checkbox"
              value="portrait"
              checked={printDimensions.portraitIsChecked}
              onClick={() => handleDimensionsCheckbox()}
            />
            <label>portrait</label>
          </div>
        </div>

        <h2>Price</h2>

        <h3 className="createPrintSmallPrice">Small size price</h3>
        <input
          {...smallSizePrice}
          type="number"
          className="createPrintSmallPriceInput"
        ></input>

        <h3 className="createPrintMedPrice">Medium size price</h3>
        <input
          {...mediumSizePrice}
          type="number"
          className="createPrintMedPriceInput"
        ></input>

        {printDimensions.squareIsChecked ? (
          <>
            <h3 className="createPrintLargePrice">Large size price</h3>
            <input
              {...largeSizePrice}
              type="number"
              className="createPrintLargePriceInput"
            ></input>
          </>
        ) : (
          ""
        )}

        <h2>Variant IDs</h2>

        <h3>Small Size Variant ID</h3>
        <input {...smallVariantIdInput}></input>

        <h3>Medium Size Variant ID</h3>
        <input {...mediumVariantIdInput}></input>

        {printDimensions.squareIsChecked && (
          <>
            <h3>Large Size Variant ID</h3>
            <input {...largeVariantIdInput}></input>
          </>
        )}

        <h2>Upload Images</h2>

        <h3>Main image user sees on our website</h3>
        {!printUploadSuccess ? (
          // upload print image
          <button
            className="uploadBtn"
            onClick={(e: any) => showPrintDownloadWidget(e)}
          >
            {uploadPrintBtnText}
          </button>
        ) : (
          // show user file upload successful
          <div className="successfulUploadDiv">
            <img
              src="../../assets/images/success-svgrepo-com.svg"
              className="successIcon"
              alt="Green check"
            />
            <h4>
              <span>{printFilename}</span> uploaded successfully!
            </h4>
          </div>
        )}

        <h3>
          Image used to conceptualize how dope your shit looks in their home
        </h3>
        {!candidUploadSuccess ? (
          // upload candid image
          <button
            className="uploadBtn"
            onClick={(e: any) => showCandidDownloadWidget(e)}
          >
            {uploadCandidBtnText}
          </button>
        ) : (
          // show user file upload successful
          <div className="successfulUploadDiv">
            <img
              src="../../assets/images/success-svgrepo-com.svg"
              className="successIcon"
              alt="Green check"
            />
            <h4>
              <span>{candidFilename}</span> uploaded successfully!
            </h4>
          </div>
        )}

        <button className="submitBtn" onClick={(e: any) => handleSubmit(e)}>
          Create new print
        </button>
      </form>
    </section>
  );
}

const useFormInput = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  return {
    value,
    onChange: handleChange,
  };
};

export default CreatePrint;
