import React, { CSSProperties } from 'react'
import { COLORS, FONTS, FONT_WEIGHTS } from '../../constants/theme'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import useViewportType from '../../utils/useViewportType'
import { useHistory } from 'react-router'

interface Props {
  doodleLocation: string
  buttonUri: string
}

const styles: { [key: string]: CSSProperties } | any = {
  rowWrapper: (isMobile: boolean) => ({
    width: isMobile ? '87.5vw' : '80vw',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '32px auto'
  }),
  button: (isMobile: boolean) => ({
    border: `${isMobile ? '2px' : '3px'} solid ${COLORS.darkBlue}`,
    borderRadius: 9000,
    fontFamily: FONTS.sansSerif,
    color: COLORS.darkBlue,
    fontWeight: isMobile ? FONT_WEIGHTS.medium : FONT_WEIGHTS.mediumLight,
    fontSize: isMobile ? '16px' : '30px',
    padding: isMobile ? '4px 8px' : '8px 12px',
    display: 'flex',
    flexWrap: 'nowrap',
  }),
  arrow: (isMobile: boolean) => ({
    width: isMobile ? '24px' : '64px',
    margin: 'auto 0 auto 12px'
  }),
  parallaxDoodle: (doodleLocation: string, isMobile: boolean) => ({
    marginTop: '-36px',
    width: isMobile ? '200px' : '400px',
    height: isMobile ? '125px' : '250px',
    backgroundImage: `url(${doodleLocation})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    zIndex: -1
  })
}

const ReadMore = ({ doodleLocation, buttonUri }: Props) => {
  const isMobile = useViewportType(700)
  const history = useHistory()

  return (
    <ParallaxProvider>
      <div style={styles.rowWrapper(isMobile)}>
        <button style={styles.button(isMobile)} onClick={() => history.push(buttonUri)}>
          Read more{' '}
          <img style={styles.arrow(isMobile)} src='../../assets/images/arrow.svg' alt='Read More'></img>
        </button>

        <Parallax speed={isMobile ? 5 : 10} style={{ position: 'relative' }}>
          {/* placeholder for doodle */}
          <div style={styles.parallaxDoodle(doodleLocation, isMobile)}></div>
        </Parallax>
      </div>
    </ParallaxProvider>
  )
}

export default ReadMore
