import React from "react";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "./style.css";

interface Window {
  cloudinary: any;
  location: any;
}

declare var window: Window;

function CreateStickersPins() {
  const printName = useFormInput("");
  const descName = useFormInput("");
  const description = useFormInput("");
  const price = useFormInput("");
  const quantity = useFormInput("");

  //* Primary image Cloudinary state
  // true if uploading primary image was successful
  const [primaryUploadSuccess, setPrimaryUploadSuccess] =
    useState<boolean>(false);
  // location of uploaded image to be stored in database
  const [primaryCloudinaryLocation, setPrimaryCloudinaryLocation] =
    useState<string>();
  // clothing filename to be displayed once image is uploaded
  const [primaryFilename, setPrimaryFilename] = useState<string>();
  // upload primary button text
  const [uploadPrimaryBtnText, setUploadPrimaryBtnText] = useState<string>(
    "Upload primary image"
  );

  //* Secondary image Cloudinary state
  // true if uploading secondary image was successful
  const [secondaryUploadSuccess, setSecondaryUploadSuccess] =
    useState<boolean>(false);
  // location of uploaded image to be stored in database
  const [secondaryCloudinaryLocation, setSecondaryCloudinaryLocation] =
    useState<string>();
  // print filename to be displayed once image is uploaded
  const [secondaryFilename, setSecondaryFilename] = useState<string>();
  // upload secondary button text
  const [uploadSecondaryBtnText, setUploadSecondaryBtnText] = useState<string>(
    "Upload secondary image"
  );

  //* Supplementary image Cloudinary state
  // true if uploading supplementary image was successful
  const [supplementaryUploadSuccess, setSupplementaryUploadSuccess] =
    useState<boolean>(false);
  // location of uploaded image to be stored in database
  const [supplementaryCloudinaryLocation, setSupplementaryCloudinaryLocation] =
    useState<string>();
  // print filename to be displayed once image is uploaded
  const [supplementaryFilename, setSupplementaryFilename] = useState<string>();
  // upload supplementary button text
  const [uploadSupplementaryBtnText, setUploadSupplementaryBtnText] =
    useState<string>("Upload supplementary image");

  // //* Print image Cloudinary upload widget
  // // widget for uploading Print images to Cloudinary
  // let merchDownloadWidget = window.cloudinary.createUploadWidget(
  //   {
  //     cloudName: "rflctveq",
  //     uploadPreset: "qirahbiz",
  //     folder: "StickersPins",
  //     sources: ["local"],
  //   },
  //   (error: any, result: any) => {
  //     if (result.event === "success") {
  //       setMerchUploadSuccess(true);
  //       setMerchFilename(
  //         result.info.original_filename + "." + result.info.format
  //       );
  //       setMerchCloudinaryLocation(result.info.secure_url);
  //     }

  //     if (result.event === "close") setUploadMerchBtnText("Upload Merch Image");
  //   }
  // );
  // // function for opening upload widget for Prints
  // const showMerchDownloadWidget = (e: any) => {
  //   setUploadMerchBtnText("Loading...");
  //   e.preventDefault();
  //   merchDownloadWidget.open();
  // };

  //* Primary image Cloudinary upload widget
  // widget for uploading primary images to Cloudinary
  let primaryDownloadWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: "rflctveq",
      uploadPreset: "qirahbiz",
      folder: "StickersPins",
      sources: ["local"],
    },
    (error: any, result: any) => {
      if (result.event === "success") {
        setPrimaryUploadSuccess(true);
        setPrimaryFilename(
          result.info.original_filename + "." + result.info.format
        );
        setPrimaryCloudinaryLocation(result.info.secure_url);
      }

      if (result.event === "close")
        setUploadPrimaryBtnText("Upload primary image");
    }
  );
  // function for opening upload widget for primary image
  const showPrimaryDownloadWidget = (e: any) => {
    setUploadPrimaryBtnText("Loading...");
    e.preventDefault();
    primaryDownloadWidget.open();
  };

  //* Secondary image Cloudinary upload widget
  // widget for uploading secondary images to Cloudinary
  let secondaryDownloadWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: "rflctveq",
      uploadPreset: "qirahbiz",
      folder: "StickersPins",
      sources: ["local"],
    },
    (error: any, result: any) => {
      if (result.event === "success") {
        setSecondaryUploadSuccess(true);
        setSecondaryFilename(
          result.info.original_filename + "." + result.info.format
        );
        setSecondaryCloudinaryLocation(result.info.secure_url);
      }

      if (result.event === "close")
        setUploadSecondaryBtnText("Upload secondary image");
    }
  );
  // function for opening upload widget for secondary images
  const showSecondaryDownloadWidget = (e: any) => {
    setUploadSecondaryBtnText("Loading...");
    e.preventDefault();
    secondaryDownloadWidget.open();
  };

  //* Supplementary image Cloudinary upload widget
  // widget for uploading secondary images to Cloudinary
  let supplementaryDownloadWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: "rflctveq",
      uploadPreset: "qirahbiz",
      folder: "StickersPins",
      sources: ["local"],
    },
    (error: any, result: any) => {
      if (result.event === "success") {
        setSupplementaryUploadSuccess(true);
        setSupplementaryFilename(
          result.info.original_filename + "." + result.info.format
        );
        setSupplementaryCloudinaryLocation(result.info.secure_url);
      }

      if (result.event === "close")
        setUploadSupplementaryBtnText("Upload supplementary image");
    }
  );
  // function for opening upload widget for supplementary images
  const showSupplementaryDownloadWidget = (e: any) => {
    setUploadSupplementaryBtnText("Loading...");
    e.preventDefault();
    supplementaryDownloadWidget.open();
  };

  // handle user attempt to submit new print
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (printName.value === "") {
      Swal.fire({
        icon: "warning",
        title: "<span>Please enter a print name.</span>",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    } else if (descName.value === "") {
      Swal.fire({
        icon: "warning",
        title: "<span>Please enter a descriptive name.</span>",
        showConfirmButton: false,
        timer: 1500,
      });
    } else if (price.value === "" || price.value < 0.01) {
      Swal.fire({
        icon: "warning",
        title: "<span>Please enter a price greater than zero.</span>",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    } else if (quantity.value === "" || price.value < 0.01) {
      Swal.fire({
        icon: "warning",
        title: "<span>Please enter a quantity greater than zero.</span>",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    } else if (!primaryUploadSuccess) {
      Swal.fire({
        icon: "warning",
        title: "<span>Please upload an image of the merch",
      });
    } else {
      axios
        .post("/api/merch/create", {
          printName: printName.value,
          descName: descName.value,
          description: description.value,
          price: parseFloat(price.value),
          quantity: parseFloat(quantity.value),
          primaryImage: primaryCloudinaryLocation,
          secondaryImage: secondaryCloudinaryLocation || null,
          supplementaryImage: supplementaryCloudinaryLocation || null,
        })
        .then((res) => {
          Swal.fire({
            icon: "success",
            iconColor: "#41F2A0",
            title: "<span>Merch created successfully!</span>",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <section className="createPrintSection">
      <form encType="multipart/form-data" className="createPrintForm">
        <h1>Create Merch</h1>

        <h3 className="createPrintName">Print Name</h3>
        <input {...printName} className="createPrintNameInput"></input>
        <h3 className="createPrintName">Descriptive Name</h3>
        <input {...descName} className="createPrintNameInput"></input>

        <h3 className="createMerchDesc">Description</h3>
        <textarea
          {...description}
          maxLength={250}
          className="createMerchDescInput"
          placeholder="This description is visible when you visit this item's page. (It is by no means necessary to include a description)"
        ></textarea>

        <h3 className="createPrintSmallPrice">Price</h3>
        <input
          {...price}
          type="number"
          className="createPrintSmallPriceInput"
        ></input>

        <h3 className="createPrintSmallPrice">Quantity</h3>
        <input {...quantity} type="number" className="priceInput"></input>

        {!primaryUploadSuccess ? (
          // upload print image
          <button
            className="uploadBtn"
            onClick={(e: any) => showPrimaryDownloadWidget(e)}
          >
            {uploadPrimaryBtnText}
          </button>
        ) : (
          // show user file upload successful
          <div className="successfulUploadDiv">
            <img
              src="../../assets/images/success-svgrepo-com.svg"
              className="successIcon"
              alt="Green check"
            />
            <h4>
              <span>{primaryFilename}</span> uploaded successfully!
            </h4>
          </div>
        )}

        {!secondaryUploadSuccess ? (
          // upload secondary image
          <button
            className="uploadBtn"
            onClick={(e: any) => showSecondaryDownloadWidget(e)}
          >
            {uploadSecondaryBtnText}
          </button>
        ) : (
          // show user file upload successful
          <div className="successfulUploadDiv">
            <img
              src="../../assets/images/success-svgrepo-com.svg"
              className="successIcon"
              alt="Green check"
            />
            <h4>
              <span>{secondaryFilename}</span> uploaded successfully!
            </h4>
          </div>
        )}

        {!supplementaryUploadSuccess ? (
          // upload secondary image
          <button
            className="uploadBtn"
            onClick={(e: any) => showSupplementaryDownloadWidget(e)}
          >
            {uploadSupplementaryBtnText}
          </button>
        ) : (
          // show user file upload successful
          <div className="successfulUploadDiv">
            <img
              src="../../assets/images/success-svgrepo-com.svg"
              className="successIcon"
              alt="Green check"
            />
            <h4>
              <span>{supplementaryFilename}</span> uploaded successfully!
            </h4>
          </div>
        )}

        <button className="submitBtn" onClick={(e: any) => handleSubmit(e)}>
          Create new merch
        </button>
      </form>
    </section>
  );
}

const useFormInput = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  return {
    value,
    onChange: handleChange,
  };
};

export default CreateStickersPins;
