import axios from "axios";
import React from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import "./style.css";

interface StickerPin {
  id: string;
  printName: string;
  descName: string;
  price: number;
  description: string;
  quantity: number;
}

function SingleItemEdit(props: StickerPin) {
  const printName = useFormInput(props.printName);
  const descName = useFormInput(props.descName);
  const price = useFormInput(props.price);
  const description = useFormInput(props.description);
  const quantity = useFormInput(props.quantity);

  const handleSubmit = (e: Event) => {
    e.preventDefault();

    axios
      .put(`/api/merch/update-product/id/${props.id}`, {
        printName: printName.value,
        descName: descName.value,
        price: price.value,
        description: description.value,
        quantity: quantity.value,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          iconColor: "#41F2A0",
          title: "<span>Item updated successfully!</span>",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: "error",
          title:
            "<span>noooooo, something went wrong :/ try again or call that super cool dev dude you know",
          showConfirmButton: false,
          timer: 2500,
        });
      });
  };

  return (
    <section className="singleItemEditWrapper">
      <form encType="multipart/form-data" className="createPrintForm">
        <h1>
          Edit {props.printName}, {props.descName}
        </h1>

        <h3 className="createPrintName">Print Name</h3>
        <textarea
          {...printName}
          className="editItemName editTextarea"
          placeholder="show me print name"
        ></textarea>

        <h3 className="createPrintName">Descriptive Name</h3>
        <textarea
          {...descName}
          className="editItemName editTextarea"
          placeholder="is this a sticker or sumthin? O_o"
        ></textarea>

        <h3 className="createPrintSmallPrice">Price</h3>
        <textarea
          {...price}
          className="editItemPrice editTextarea"
          placeholder="10000000000.00"
        ></textarea>

        <h3 className="createMerchDesc">Description</h3>
        <textarea
          {...description}
          className="createMerchDescInput"
          placeholder="Look, it's pretty neat, alright?"
        ></textarea>

        <h3 className="createPrintSmallPrice">Quantity</h3>
        <textarea
          {...quantity}
          className="editItemQuantity editTextarea"
          placeholder="69420"
        ></textarea>

        <button className="submitBtn" onClick={(e: any) => handleSubmit(e)}>
          Save Item
        </button>
      </form>
    </section>
  );
}

const useFormInput = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  return {
    value,
    onChange: handleChange,
  };
};

export default SingleItemEdit;
