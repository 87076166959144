import React from 'react'
import useViewportType from '../../../utils/useViewportType'
import { getUser } from '../../../utils/Session'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'

const styles = {
  imgWrapper: {
    marginLeft: '16px'
  },
  imgDesktop: {
    width: '120px',
    height: '120px'
  },
  imgMobile: {
    width: '65px',
    height: '65px'
  }
}

const HomeIcon = () => {
  const isMobile = useViewportType(900)
  const history = useHistory()
  const location = useLocation()

  const role = getUser()?.role

  const handleAdminRouting = (location: { pathname: string }) => {
    if (location.pathname === '/admin') {
      history.push('/')
    } else {
      history.push('/admin')
    }
  }

  

  return (
    <button
      style={isMobile ? styles.imgWrapper : {}}
      onClick={() => role !== 'admin' ? history.push('/') : handleAdminRouting(location)}
    >
      <img
        style={isMobile ? styles.imgMobile : styles.imgDesktop}
        src='../../assets/images/nav_eq_logo.svg'
        alt='Enchanted Quill Logo - Go Home'
      />
    </button>
  )
}

export default HomeIcon
